import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../hooks/useImageSrc"
// Components
import { MapWithForm } from "../components/MapWithForm"
import { Layout } from "../components/Layout"
// Styles
import "../styles/contactsPage.sass"

export const ContactsPage = () => {
  const { src, srcSet } = useImageSrc()

  return (
    <StaticQuery
      query={graphql`
        query {
          outside: file(relativePath: { eq: "assets/img/outside.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 550) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          inside: file(relativePath: { eq: "assets/img/inside.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 550) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout
            title='Наши контакты | Студия красоты "Lab Beauty" Ирины Лучистой'
            description="Салон красоты в г. Бровары, записаться или задать вопрос"
            keywords="найти салон красоты бровары, студия красоты в Броварах, записаться в салон красоты">
            <section className="contacts">
              <MapWithForm />
              <h2>Наша студия:</h2>
              <div className="photos">
                <div className="office-one" title="Вход">
                  {src && data.outside && (
                    <GatsbyImage
                      placeholder={{
                        fallback: data.outside.childImageSharp.fluid.base64,
                      }}
                      images={{
                        fallback: {
                          src: data.outside.childImageSharp.fluid.src,
                          srcSet: data.outside.childImageSharp.fluid.srcSet,
                        },
                        sources: [
                          {
                            src: data.outside.childImageSharp.fluid[src],
                            srcSet: data.outside.childImageSharp.fluid[srcSet],
                          },
                        ],
                      }}
                      style={{ width: "100%", height: "100%" }}
                      alt="вход"
                    />
                  )}
                </div>
                <div className="office-two" title="Внутри">
                  {src && data.inside && (
                    <GatsbyImage
                      placeholder={{
                        fallback: data.inside.childImageSharp.fluid.base64,
                      }}
                      images={{
                        fallback: {
                          src: data.inside.childImageSharp.fluid.src,
                          srcSet: data.inside.childImageSharp.fluid.srcSet,
                        },
                        sources: [
                          {
                            src: data.inside.childImageSharp.fluid[src],
                            srcSet: data.inside.childImageSharp.fluid[srcSet],
                          },
                        ],
                      }}
                      style={{ width: "100%", height: "100%" }}
                      alt="внутри"
                    />
                  )}
                </div>
              </div>
            </section>
          </Layout>
        )
      }}
    />
  )
}

export default ContactsPage
